import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import pageStyles from "./styles.scss";

function designportfolio() {
    const isBrowser = typeof window !== "undefined"
    const openPortfolio = () => {
        window.open("../IMR_Portfolio_RFP.pdf");
      };

    return (  
        <main>
            {/* TOP BANNER */}
            <div
            style={{
                height: "auto",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
            >
            <div>
                <StaticImage
                placeholder="blurred"
                src="../images/imr_video_top.png"
                alt="cityscape background"
                />
            </div>
            <p
                style={{
                fontFamily: "Montserrat",
                fontSize: "2vw",
                position: "absolute",
                color: "white",
                }}
                className='resp-title'
            >
                Providing affordable high quality video production and photography
            </p>
            </div>
            {/* END TOP BANNER */}

            {/* NAV */}
            <div className="imr-nav">
            <div className="imr-inner-nav">
                <a href="/"><div className="imr-nav-link">Video</div></a>
                <a href="/designportfolio"><div className="imr-nav-link">Design</div></a>
                <a href="/references"><div className="imr-nav-link">References</div></a>
            </div>
            </div>
            {/* END NAV */}
            
            {isBrowser && window.innerWidth > 700 && 
                <embed src="../IMR_Portfolio_RFP.pdf" width="100%" height='1000px' />
            }
            {isBrowser && window.innerWidth < 700 &&
                <div style={{display:'flex',justifyContent:'center'}}>
                    <div className='portfolio-button' onClick={openPortfolio}>
                        <h2 style={{fontFamily:'Montserrat',fontWeight: '600'}}>Portfolio PDF</h2>
                    </div>
                </div>
            }

        </main>
    );
}

export default designportfolio;